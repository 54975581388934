import { css } from '@emotion/core';

import { buttons } from './button.styles';
import { inputs } from './input.styles';
import { layout } from './layout.styles';
import { color } from './mixins.styles';
import { mqFrom } from './responsive.styles';
import { spacers } from './spacers.styles';
import { typescale } from './typescale.styles';
import { colors, fontWeights, globalRadial } from './vars.styles';

const flexLayout = css`
  ${layout.wrapper}

  width: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;

  ${mqFrom.Med} {
    max-height: calc(100vh - 97px - 140px);
  }
`;

const wrapper = css`
  display: flex;
  flex: 1;
  overflow: hidden;
  margin-top: 87px;

  ${mqFrom.Med} {
    margin-top: 0;
  }
`;

const title = css`
  padding: 40px 0 22px 0;
  display: none;

  ${mqFrom.Med} {
    display: block;
  }
`;

const listWrapperDesktop = css`
  width: 330px;
  display: none;
  flex-direction: column;

  ${mqFrom.Med} {
    display: flex;
  }
`;

const listWrapperMobile = css`
  width: 100%;
  display: flex;
  flex-direction: column;
  border-top: 1px solid rgba(0, 0, 0, 0.1);

  ${mqFrom.Med} {
    display: none;
  }
`;

const detailsWrapperDesktop = css`
  flex: 1;
  overflow-y: auto;
  display: none;

  ${mqFrom.Med} {
    display: block;
  }
`;

const detailsWrapperMobile = css`
  display: block;
  width: 100%;

  ${mqFrom.Med} {
    display: none;
  }
`;

const mailCategories = css`
  display: none;
  height: 40px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-left: none;
  justify-content: flex-start;
  align-items: center;

  ${mqFrom.Med} {
    display: flex;
  }
`;

const offerTypeTabs = css`
  height: 40px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-left: none;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 32px;
`;

const categoryBtn = css`
  ${buttons.container}

  padding-left: 16px;
  padding-right: 16px;
  height: 100%;
  border-radius: 0;
`;

const categoryBtnActive = css`
  border-top: 2px solid ${colors.primary};
  margin-top: -2px;
`;

const search = css`
  display: none;
  height: 64px;
  border-right: 1px solid rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding: 12px 16px 12px 0;
  position: relative;

  ${mqFrom.Med} {
    display: block;
  }
`;

const searchIcon = css`
  position: absolute;
  left: 16px;
  top: 50%;
  transform: translate(0%, -50%);
`;

const searchInput = css`
  ${inputs.input.small}

  background-color: ${colors.backgroundGray};
  padding-left: 44px;
`;

const mailList = css`
  flex: 1;
  overflow-y: scroll;

  ${mqFrom.Med} {
    border-right: 1px solid rgba(0, 0, 0, 0.1);
  }
`;

const msgItem = css`
  position: relative;
  height: 50px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  transition: background-color 0.15s ease-out;

  &:hover {
    background-color: #f8f8f8;
    cursor: pointer;
  }

  & > button {
    ${buttons.container}

    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 28px;
    padding-right: 24px;
  }
`;

const msgItemActive = css`
  background-color: rgba(54, 134, 247, 0.1);
  transition: background-color 0.15s ease-out;
`;

const mailActions = css`
  height: 40px;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: space-between;
  align-items: center;
  opacity: 0.5;
  ${typescale.tiny}

  ${mqFrom.Med} {
    padding-left: 110px;
  }
`;

const actionBtn = css`
  ${buttons.container}
  ${typescale.tiny}
  height: 100%;
  margin-right: 24px;
  color: ${colors.dark};
  transition: color 0.15s ease-out;

  path {
    transition: stroke 0.15s ease-out;
    stroke: black;
  }

  &:hover {
    color: ${colors.primary};

    path {
      stroke: ${colors.primary};
    }
  }

  span {
    display: none;
  }

  ${mqFrom.Med} {
    span {
      display: inline-block;
    }
  }
`;

const mailActionsPagination = css`
  display: flex;
  align-items: center;
`;

const distributorDetails = css`
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding: 20px;

  ${mqFrom.Med} {
    padding: 40px 0 40px 110px;
  }
`;

const detailsRow = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  color: black;
  max-width: 784px;

  &:last-child {
    margin-bottom: 0;
  }

  span {
    ${typescale.tiny}
    letter-spacing: 1px;
    color: #3c3737;
    opacity: 0.5;
  }

  p {
    ${typescale.small}
  }
`;

const detailsRowWithBtn = css`
  width: 100%;
  max-width: 100%;
`;

const detailsTitle = css`
  h3 {
    font-weight: ${fontWeights.weightBold};
  }

  p {
    ${typescale.tiny}
    letter-spacing: 1px;
    opacity: 0.4;
  }
`;

const offerDetailsDesktop = css`
  padding: 40px 0;
  flex-direction: column;
  display: none;

  ${mqFrom.Med} {
    display: flex;
  }
`;

const withLeftPadding = css`
  ${mqFrom.Med} {
    padding-left: 110px;
  }
`;

const productsTableDesktop = css`
  width: 100%;
  margin-bottom: 18px;
  display: none;

  thead {
    ${typescale.tiny}
    text-transform: uppercase;
    letter-spacing: 1px;
    color: ${colors.textSecondary};
    opacity: 0.5;
  }

  tr {
    & > td,
    & > th {
      text-align: center;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    }

    & > th:last-child,
    & > td:last-child {
      text-align: end;
    }
  }

  ${mqFrom.Med} {
    display: table;
  }
`;

const offerSummary = css`
  margin: 22px 20px 8px 20px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);

  ${mqFrom.Med} {
    width: 400px;
    margin: 0;
    margin-bottom: 16px;
    margin-left: auto;
  }
`;

const summaryRow = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
  ${typescale.small}
  color: ${colors.dark};
  margin-bottom: 8px;

  & > span {
    opacity: 0.6;
  }
`;

const summaryRowTotal = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: ${colors.dark};
  width: 100%;
  padding: 0 20px;

  & > p {
    font-weight: ${fontWeights.weightBold};

    & > span {
      text-transform: uppercase;
    }
  }

  ${mqFrom.Med} {
    padding: 0;
    margin-left: auto;
    width: 400px;
  }
`;

const offerFooter = css`
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  ${mqFrom.Med} {
    padding: 40px 0;
    padding-left: 110px;
  }
`;

const footerSection = css`
  width: 400px;
`;

const footerSectionDesktop = css`
  ${footerSection}
  display: none;

  ${mqFrom.Med} {
    display: block;
  }
`;

const requestGroup = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid rgba(0, 0, 0, 0.3);
  padding: 8px 24px;
  cursor: default;

  ${mqFrom.Med} {
    padding: 8px 24px 8px 0;
  }
`;

const requestGroupId = css`
  font-weight: ${fontWeights.weightBold};
  color: ${color(colors.dark, 0.8)};
`;

const requestGroupAmount = css`
  color: ${color(colors.textSecondary, 0.8)};
`;

const arrows = css`
  cursor: pointer;

  path {
    stroke: black;
    transition: stroke 0.15s ease-out;
  }

  &:hover {
    path {
      stroke: ${colors.primary};
    }
  }
`;

const mailNotRead = css`
  font-weight: ${fontWeights.weightBold};
`;

const mapLocation = css`
  ${buttons.container}

  color: ${colors.primary};
  cursor: pointer;
`;

const externalOfferWrapper = css`
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  padding-bottom: 40px;

  ${mqFrom.Med} {
    padding-left: 110px;
  }
`;

const internalOfferWrapper = css`
  ${externalOfferWrapper}
`;

const offerFooterBtnWrapper = css`
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  ${mqFrom.Sml} {
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
  }
`;

const offerCancelButton = css`
  padding: 0 65px;
  ${mqFrom.Sml} {
    margin-top: 0;
    margin-right: 20px;
  }
`;

const dropzone = css`
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  height: 96px;
  border: 1px dashed #b30000;
  border-radius: 12px;
  background: #f8f8f8;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 32px;
  margin-bottom: 40px;
  cursor: pointer;
  transition: opacity 0.15s ease-out;

  &:hover {
    opacity: 0.6;
  }

  ${mqFrom.Med} {
    width: 400px;
    margin-left: unset;
  }
`;

const dropzoneLoading = css`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const dropzoneSpinnerColor = css`
  border-top: 2px solid ${colors.primary};
  border-right: 2px solid ${colors.primary};
`;

const uploadedDocWrapper = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;

  ${mqFrom.Med} {
    width: 400px;
    padding: 40px 0;
  }
`;

const removeDoc = css`
  cursor: pointer;
  transition: opacity 0.15s ease-out;
  min-width: 18px;

  &:hover {
    opacity: 0.6;
  }
`;

const offerFooterWrapper = css`
  padding: 20px;

  ${mqFrom.Med} {
    padding: 40px 0 60px 110px;
  }
`;

const offerFooterTextarea = css`
  margin-bottom: 40px;

  ${mqFrom.Med} {
    width: 400px;
  }
`;

const internalOfferInput = css`
  ${inputs.input.normal}

  width: 56px;
  padding: 10px 12px;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &[type='number'] {
    -moz-appearance: textfield;
  }
`;

const internalOfferPriceInput = css`
  ${internalOfferInput}

  width: 132px;
`;

const paleteImg = css`
  width: 48px;
  height: 48px;
  background: ${colors.backgroundGray};
  border-radius: ${globalRadial}px;
  display: inline-block;
`;

const internalOfferTd = css`
  ${typescale.small}
  color: ${colors.dark};
  padding-left: 40px;

  & > p {
    text-align: left;
  }

  p:last-of-type {
    ${typescale.tiny}

    opacity: 0.6;
  }
`;

const answeredMailWrapper = css`
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);

  & > p:first-of-type {
    margin: 12px 0 4px 20px;
    ${typescale.tiny}
    letter-spacing: 1px;
    color: ${colors.textSecondary};
    opacity: 0.5;
  }

  ${mqFrom.Med} {
    padding: 40px 0 48px 110px;

    & > p:first-of-type {
      margin-bottom: 20px;
      margin-left: 0;
    }
  }
`;

const answeredMailRequest = css`
  padding: 28px 0;

  ${mqFrom.Med} {
    padding: 40px 0 60px 110px;
  }
`;

const accordionBtn = css`
  padding: 16px 20px 20px 20px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  cursor: pointer;
  outline: none;
  display: flex;
  justify-content: space-between;
  align-items: center;

  ${mqFrom.Med} {
    padding-left: 0;
    padding-right: 0;
  }
`;

const greenDot = css`
  position: absolute;
  top: 50%;
  left: 14px;
  transform: translateY(-50%);
`;

const toastWrapper = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
`;

const toastInfo = css`
  display: flex;
`;

const distributorName = css`
  max-width: 155px;
  overflow: hidden;
`;

const emptyState = css`
  color: #909090;
  padding-top: 82px;
  padding-left: 118px;
`;

const mobileHeader = css`
  display: flex;
  justify-content: space-between;
  padding: 20px 26px;
`;

const mobileHeaderTabs = css`
  ${buttons.container}
  ${typescale.medium}
  padding: 0;
`;

const mobileTabsModal = css`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`;

const mobileTabBtn = css`
  ${buttons.container}
  ${typescale.medium}
  ${spacers.bottom.large}
`;

const mobileSearchField = css`
  margin: 0;
  position: relative;
`;

const mobileSearchInput = css`
  ${inputs.input.small}
  margin: 15px 26px;
  border: none;
`;

const mobileBackBtn = css`
  ${buttons.container}
  padding-right: 40px;
  padding-left: 20px;

  ${mqFrom.Med} {
    display: none;
  }
`;

const detailsEmailDesktop = css`
  display: none;

  ${mqFrom.Med} {
    display: block;
  }
`;

const detailsEmailMobile = css`
  display: block;
  color: ${colors.primary};

  span {
    ${typescale.tiny}
    letter-spacing: 1px;
    color: #3c3737;
    opacity: 0.5;
  }

  ${mqFrom.Med} {
    display: none;
  }
`;

const detailsAddress = css`
  ${typescale.small}
  opacity: 0.5;
`;

const productsAccordionDesktop = css`
  display: none;
  margin-top: 20px;

  ${mqFrom.Med} {
    display: block;
  }
`;

const productsAccordionMobile = css`
  display: block;
  padding-bottom: 20px;

  ${mqFrom.Med} {
    display: none;
  }
`;

const productsListMobile = css`
  display: flex;
  flex-direction: column;
  padding-left: 20px;
`;

const productsListItemMobile = css`
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding: 28px 20px 32px 0;
`;

const productsListItemTitle = css`
  display: flex;
  align-items: center;
  margin-bottom: 16px;
`;

const productsListItemInfo = css`
  display: flex;
  justify-content: space-between;
  margin-left: 60px;

  & > div {
    flex: 1;
    ${typescale.small}

    span {
      ${typescale.tiny}
      opacity: 0.5;
      letter-spacing: 1px;
      color: ${colors.textSecondary};
      text-transform: uppercase;
    }
  }

  & > div:last-of-type {
    text-align: end;
  }
`;

const summaryTotalMobile = css`
  display: flex;
  justify-content: space-between;
  margin: 16px 20px 28px 20px;
  ${typescale.small}
  color: ${colors.dark};

  & > p:last-of-type {
    font-weight: ${fontWeights.weightBold};
  }

  & > p {
    & > span {
      text-transform: uppercase;
    }
  }
`;

const summaryRemark = css`
  color: ${colors.textSecondary};

  & > span {
    margin-bottom: 20px;
    ${typescale.tiny}
    letter-spacing: 1px;
    opacity: 0.5;
    text-transform: uppercase;
    display: block;
  }

  & > p {
    ${typescale.small}
    opacity: 0.8;
  }
`;

const mobile = css`
  ${mqFrom.Med} {
    display: none;
  }
`;

const desktop = css`
  display: none;

  ${mqFrom.Med} {
    display: flex;
  }
`;

const distributorLogoFull = css`
  max-height: 40px;
  margin-bottom: 10px;
`;

const distributorLogoWrapper = css`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 8px;
`;

const pdfTextWrapper = css`
  display: flex;
  align-items: center;
`;

const distributorLogoCircle = css`
  width: 32px;
  height: 32px;
  margin-right: 8px;
  border-radius: 50%;
`;

const deleteOfferWrapper = css`
  width: 25%;
  margin-left: auto;
  margin-right: 0;
  padding-bottom: 20px;
`;

export const inbox = {
  flexLayout,
  wrapper,
  title,
  listWrapperDesktop,
  listWrapperMobile,
  detailsWrapperDesktop,
  detailsWrapperMobile,
  mailCategories,
  categoryBtn,
  categoryBtnActive,
  search,
  searchIcon,
  searchInput,
  mailList,
  msgItem,
  msgItemActive,
  mailActions,
  actionBtn,
  mailActionsPagination,
  distributorDetails,
  detailsRow,
  detailsRowWithBtn,
  detailsTitle,
  offerDetailsDesktop,
  withLeftPadding,
  productsTableDesktop,
  offerSummary,
  summaryRow,
  summaryRowTotal,
  offerFooter,
  footerSection,
  footerSectionDesktop,
  requestGroup,
  requestGroupId,
  requestGroupAmount,
  arrows,
  mailNotRead,
  mapLocation,
  externalOfferWrapper,
  internalOfferWrapper,
  offerFooterBtnWrapper,
  dropzone,
  dropzoneLoading,
  dropzoneSpinnerColor,
  uploadedDocWrapper,
  removeDoc,
  offerFooterWrapper,
  offerFooterTextarea,
  internalOfferInput,
  internalOfferPriceInput,
  paleteImg,
  internalOfferTd,
  answeredMailWrapper,
  answeredMailRequest,
  accordionBtn,
  greenDot,
  toastWrapper,
  toastInfo,
  distributorName,
  emptyState,
  mobileHeader,
  mobileHeaderTabs,
  mobileTabsModal,
  mobileTabBtn,
  mobileSearchField,
  mobileSearchInput,
  mobileBackBtn,
  detailsEmailDesktop,
  detailsEmailMobile,
  detailsAddress,
  productsAccordionDesktop,
  productsAccordionMobile,
  productsListMobile,
  productsListItemMobile,
  productsListItemTitle,
  productsListItemInfo,
  summaryTotalMobile,
  summaryRemark,
  mobile,
  desktop,
  offerTypeTabs,
  distributorLogoFull,
  distributorLogoCircle,
  distributorLogoWrapper,
  pdfTextWrapper,
  offerCancelButton,
  deleteOfferWrapper,
};
